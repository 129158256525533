import React from 'react';
import { Link } from "gatsby"
import "../../styles/super-sathi.css"
import Arrow from "../../images/super-sathi/arrow.png"
import logo from '../../images/super-sathi/logo.png'
import backarrow from "../../images/super-sathi/backarrow.png";
import { Helmet } from "react-helmet"

class Landing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerShow: false,
            name: '',
            contactNumber: '',
            pincode: '',
            mooofeedBagCount: ''
        };
    }

    componentDidMount(){
        // alert('call')
        console.log(window,"<=====window")
        window.fbq("trackCustom", "Visited Feed Form");
    }

    submitHandler = async () => {
        if (this.state.name === '') {
            alert("Please enter your name");
            return;
        }
        if (this.state.contactNumber === '' || this.state.contactNumber.length !== 10) {
            alert("Please enter 10-digit mobile number");
            return;
        }
        if (this.state.pincode === '') {
            alert("Please enter pincode");
            return;
        }
        if (this.state.mooofeedBagCount === '') {
            alert("Please enter BagCount");
            return;
        }
        let userInfo = {
            name: this.state.name,
            contact: this.state.contactNumber,
            pincode: this.state.pincode,
            mooofeed_bag_count: this.state.mooofeedBagCount
        }
        let payload = {
            Type: "Feed Landing Page",
            FormData: JSON.stringify(userInfo),
            Contact: this.state.contactNumber,
            // LanguageID: 101
        }
        console.log(payload)
        const rawResponse = await fetch('https://api.mooofarm.com/v3/api/marketing/forms/save', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const content = await rawResponse.json();
        if (content.status == "success") {
            let utmObj;
            const queryString = window.location.search
            const paramsofquery = new URLSearchParams(queryString);
            utmObj={
                "User Name": this.state.name,
                "User Phone Number": this.state.contactNumber,
                "Pincode": this.state.pincode,
                "Number Of Bags": this.state.mooofeedBagCount                
            }
            if (paramsofquery.get('utm_source') && paramsofquery.get('utm_medium') && paramsofquery.get('utm_campaign') && paramsofquery.get('utm_content')){
                utmObj['UTM Source']=paramsofquery.get('utm_source');
                utmObj['UTM Medium']=paramsofquery.get('utm_medium');
                utmObj['UTM Campaign']=paramsofquery.get('utm_campaign');
                utmObj['UTM Content']=paramsofquery.get('utm_content');
            }
            this.setState({ name: '', contactNumber: '', pincode: '', mooofeed_bag_count: '' })
            window.gtag_report_conversion_p(utmObj)
            window.fbq("trackCustom", "Feed Form Submitted",{utmObj});
            window.analytics.track("Feed Form Submitted",{utmObj})
            window.location.href = "/feedpun/thankyou"
        } else {
            alert("Something went wrong. Please try again.")
        }
    }

    render() {
        return (
            <div className='main-container super-sathi-container'>
                <Helmet>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charset="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    />
                    <script src="js/script.js"></script>
                    <script src="https://kit.fontawesome.com/dd15047d18.js" crossorigin="anonymous"></script>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
                </Helmet>
                <div className="container">
                    <div className='row'>
                        <div className={"fixed-header"}>
                            <img
                                src={backarrow}
                                className="backarrow-img"
                                onClick={() => { window.location.href = "/feedpun" }}
                            />
                            <img src={logo} className="moofarm-logo" />
                            <div></div>
                        </div>
                    </div>
                </div>
                <div className="container super-sathi-form">
                    <div className=''>
                        <div className="form-group">
                            <label for="name">ਤੁਹਾਡਾ ਨਾਂ</label>
                            <input type="text" className="form-control" id="name" placeholder="ਨਾਂ ਇੱਥੇ ਲਿਖੋ" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                        </div>
                        <div className="form-group">
                            <label for="yournumber">ਤੁਹਾਡਾ ਨੰਬਰ</label>
                            <input type="text" className="form-control" id="yournumber" placeholder="ਨੰਬਰ ਇੱਥੇ ਲਿਖੋ" value={this.state.contactNumber} pattern="[0-9]*" maxLength={10} onChange={(e) => { e.target.validity.valid ? this.setState({ contactNumber: e.target.value }) : alert("Contact number should contain Numbers only ") }} />
                        </div>
                        <div className="form-group">
                            <label for="yournumber">ਪਿਨਕੋਡ</label>
                            <input type="text" className="form-control" id="yournumber" placeholder="ਪਿਨਕੋਡ ਇੱਥੇ ਲਿਖੋ" value={this.state.pincode} onChange={(e) => { this.setState({ pincode: e.target.value }) }} />
                        </div>
                        <div className="form-group">
                            <label for="yournumber">ਤੁਹਾਨੂੰ ਮੂਫੀਡ ਦੇ ਕਿੰਨੇ ਬੈਗ ਚਾਹੀਦੇ ਹਨ</label>
                            <input type="text" className="form-control" id="yournumber" placeholder="ਬੈਗ ਦੀ ਗਿਣਤੀ ਲਿਖੋ" value={this.state.mooofeedBagCount} onChange={(e) => { this.setState({ mooofeedBagCount: e.target.value }) }} />
                        </div>
                        <button className="submit-btn" onClick={() => { this.submitHandler() }}>ਜਮ੍ਹਾ ਕਰੋ <img src={Arrow} className="moofeed-btn-arrow" alt="Arrow Img" /></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Landing
